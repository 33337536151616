<template>
    <div class="grid">
        <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <div class="card">

                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-2" style="margin-top: 1%;text-align:right">
                        <FileUpload mode="basic" id="fileupload1" ref="fileupload1" chooseLabel="Upload Users"
                            :maxFileSize="1000000" @change="fileuploaduser($event)" />
                    </div>

                    <div class="field col-12 md:col-12" style="margin-top: 1%;">
                        <DataTable :value="products" :loading="loading" :lazy="true" :rows="limit" :filters="filters"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[5, 10, 25]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            responsiveLayout="scroll" style="font-size: 12px" :rowHover="true" showGridlines>
                            <template #empty>
                                <b style="text-align:center">No records found.</b>
                            </template>
                            <template #header>
                                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                    <h5 class="m-0">
                                        <b> New User List</b>
                                    </h5>
                                    Total Records:{{ this.products.length }}
                                </div>
                            </template>

                            <Column field="first_name" headerStyle="min-width:10rem;" header="First Name">
                                <template #body="{ data }">
                                    <InputText id="first_name" type="text" size="10"
                                        v-model.trim="data.first_name" required="true" autofocus />
                                </template>
                            </Column>
                            <Column field="last_name" headerStyle="min-width:10rem;" header="Last Name">
                                <template #body="{ data }">
                                    <InputText id="last_name" type="text" size="10"
                                        v-model.trim="data.last_name" required="true" autofocus />
                                </template>
                            </Column>
                            <Column field="mobile_number" headerStyle="min-width:10rem;" header="Mobile Number">
                                <template #body="{ data }">
                                    <InputText id="mobile_number" type="text" size="10" v-model.trim="data.mobile_number"
                                        required="true" autofocus />
                                </template>
                            </Column>
                             <Column field="password" headerStyle="min-width:10rem;" header="Password">
                                <template #body="{ data }">
                                    <InputText id="password" type="text" size="10" v-model.trim="data.password"
                                        required="true" autofocus />
                                </template>
                            </Column>
                            <Column header="Action" headerStyle="min-width:2rem;">
              <template #body="slotProps">
               
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                />
              </template>
            </Column>
                           
                        </DataTable>
                    </div>
                    <!-- add excel functionality end -->








                    <div class="field col-12 md:col-4" style="margin-top: 1%;"></div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <!-- <Button v-if="product._id" label="Update" class="p-button-success mr-2" @click="Add_user" v-tooltip.bottom="'Update'" /> -->
                        <Button label="Submit" class="p-button-success mr-2" @click="submit_details"
                            v-tooltip.bottom="'Submit'" />
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button label="Reset" class="p-button-danger mr-2" v-tooltip.bottom="'Reset'" @click="reset_user" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
//import ProductService from "../service/ProductService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
import readXlsxFile from 'read-excel-file'
import axios from "axios";
export default {
    data() {
        return {

            limit: 10,
            filters: {},
            products: [],
            status: "",

            isLoading: false,
            isLoadingModel: false,
            loading: false,
            fullPage: true,
            file_attachment: '',
            id: "",
            list: [],
            client_role_group: '',








            product: {},
            submitted: false,
        }

    },
    productService: null,
    methods: {

        confirmDeleteProduct(data)
    { 
    //  if(confirm("Are you sure you want to delete"))
    //  { 
    //   this.products = this.products.filter(item => item.mobile_number != data.mobile_number);
    //  }
                        this.$swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        this.products = this.products.filter(item => item.mobile_number != data.mobile_number);
                        this.$swal.fire(
                        'Deleted!',
                        'User has been deleted.',
                        'success'
                        )
                    }
                    })
    },
        submit_details() {
            if (this.products == [] || this.products == null || this.products == undefined || this.products.length == 0) {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Add Atlest one User",
                    life: 3000
                });
                return false;
            }
            for (var i = 0; i < this.products.length; i++) 
            {
                if (this.products[i].mobile_number) {
                    this.products[i].mobile_number=this.products[i].mobile_number.toString();
                }
                // if(this.checkName(this.products[i].name)!='')
                // {
                //   alert("Invalid Name "+this.checkName(this.products[i].name));
                //   return false;
                // }

                // if(this.checkMobile(this.products[i].mobile_number.toString())!='')
                // {

                //   alert("Invalid Password "+this.checkMobile(this.products[i].mobile_number.toString()));
                //   return false;
                // }
            }
            var data = {
                "user_details": this.products,
                "client_id": localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '',
                "user_id": localStorage.getItem("id")

            };
          


            this.isLoadingModel = true;
            var promise = apis.bulkuserupload_save(data);
            promise
                .then((responseapi) => {
                    this.$swal(responseapi.data.message);
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"usermaster"});
                })
                .catch((error) => {
                    
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"usermaster"});



                });



        },

        formatDate(year) {
            if (!year) {
                return '';
            }
            const date = new Date(year, 0, 1); // Create a new Date object with the given year and January 1st
            const options = {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short'
            }; // Define the formatting options

            const formattedDate = date.toLocaleString('en-US', options); // Format the date as a string

            return formattedDate;
        },


        fileuploaduser(event) {
            //alert("here");

            console.log(event);
            const input = this.$refs.fileupload1;
            //console.log(input);

            if (input.files[0].name.slice((Math.max(0, input.files[0].name.lastIndexOf(".")) || Infinity) + 1) == 'xlsx') {
                readXlsxFile(input.files[0]).then((rows) => {
                    for (var i = 1; i < rows.length; i++) 
                    {
                        this.products.push({

                            first_name   :  rows[i][0],
                            last_name    :  rows[i][1],
                            mobile_number:  rows[i][2],
                            password     :  rows[i][3],
                            _id          :  '0',
                            sr_no        :  this.products.length + 1,
                            status       :  "Active",
                        });

                    }
                    //console.log(this.products);
                })
            }
            else {
                alert("Please select valid file");
            }
        },
       












        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            };
        },







        reset_user() {
            this.product = {};
            this.products=[];
            this.submitted = false;
            this.status_dropdownItem = null;
            this.dropdownItem = null;
            this.state_dropdownItem = null;
            this.location_search = '';
            this.$refs.file_attachment.files = '';
            this.show_name = "Add";
        },
    },
    async created() {
        //this.productService = new ProductService();
        this.id = localStorage.getItem('id');
    },

    components: { downloadExcel: JsonExcel },
}
</script>
